import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `portfolio`, `design`, `designer`, `rita`, `vilaret`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>
            London based designer with a passion for helping brands tell their unique stories.
          </h2>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
          <h3 id="dynamic-styles">Profile</h3>
          <p>
            I'm a Design Director with multi-disciplinary expertise and a true
            passion for helping brands tell their unique stories across different touchpoints.
          </p>
          <p>
            Throughout my career I have worked with many varied teams and I have 
            been challenged in different areas, always with a hands-on approach, 
            solving problems, adapting to the contraints of the projects, 
            and learning new skills as I go.
          </p>
          <p>
            If you'd like to get in touch with me I'm on <a 
                href="https://www.linkedin.com/in/ritavilaret/"
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a> and always keen to contribute with my experience of global brands,
              my designer flair and attention to detail, and a positive attitude.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "profile-pic.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
